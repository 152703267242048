import { graphql } from 'gatsby';
import React from 'react';
import Heading from '../components/Heading';
import Layout from '../components/Layout';
import Ogp, { SiteProps } from '../components/Ogp';
import PostList, { PostListProps } from '../components/PostList';
import TagIcon from '../icons/Tag/Tag';

type Props = {
  data: {
    site: SiteProps;
    allMarkdownRemark: {
      posts: PostListProps;
    };
  };
  pageContext: {
    tag: string;
  };
};

const Tags = (props: Props) => {
  const { data, pageContext } = props;
  const { posts } = data.allMarkdownRemark;
  const title = `タグ "${pageContext.tag}" の記事一覧`;
  return (
    <Layout>
      <Ogp metadata={data.site} title={title} />
      <Heading icon={<TagIcon alt={title} />}>{title}</Heading>
      <PostList posts={posts} />
    </Layout>
  );
};

export const query = graphql`
  query TagPageQuery($tag: String!) {
    site {
      ...OgpFragment
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      posts: edges {
        ...PostListFragment
      }
    }
  }
`;

export default Tags;
